document.addEventListener('DOMContentLoaded', () => {
  const internalNavTOC = document.querySelector('.js-internal-nav');
  const tocListItems = [...document.querySelectorAll('.js-toc-item')];
  const sections = document.querySelectorAll('.js-toc-section');
  const nextButton = document.querySelector('.js-internal-nav-next');
  const prevButton = document.querySelector('.js-internal-nav-prev');
  const innerNav = document.querySelector('.js-internal-nav ul');
  const buttonWrapper = document.querySelector('.js-internal-nav-buttons');
  const navViewport = innerNav.getBoundingClientRect();
  const url = window.location.origin + window.location.pathname;

  window.addEventListener('resize', () => {
    if (innerNav.offsetWidth < innerNav.scrollWidth) {
      buttonWrapper.classList.add('active');
    }
  });

  if (innerNav.offsetWidth < innerNav.scrollWidth) {
    buttonWrapper.classList.add('active');
  }

  // handles upping the z-index when sticky. This way the main nav isn't UNDER the TOC
  // when in dropdown mode.

  const scrollToSection = (target) => {
    const targetSection = document.querySelector(target);
    const targetPosition = targetSection.getBoundingClientRect().top;
    const offsetPosition = targetPosition + window.pageYOffset - 85;
    window.history.pushState({}, '', url + target);

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const isInNavViewport = (element) => {
    const elementPosition = element.getBoundingClientRect();
    return (
      elementPosition.left >= navViewport.left &&
      elementPosition.right <= navViewport.right + 10
    );
  };

  const updateVisibleEls = (arr) => {
    arr.forEach((element, index) => {
      const previousElementHidden = arr[index - 1]
        ? isInNavViewport(element) && !isInNavViewport(arr[index - 1])
        : false;
      const nextElementHidden = arr[index + 1]
        ? isInNavViewport(element) && !isInNavViewport(arr[index + 1])
        : false;
      element.classList.remove('last-visible');
      if (previousElementHidden || (isInNavViewport(element) && index === 0)) {
        element.classList.add('first-visible');
      } else {
        element.classList.remove('first-visible');
      }
      if (
        nextElementHidden ||
        (isInNavViewport(element) && index === arr.length - 1)
      ) {
        element.classList.add('last-visible');
      } else {
        element.classList.remove('last-visible');
      }
    });
  };

  updateVisibleEls(tocListItems);

  const disableButtons = (navItems) => {
    if (!navItems || navItems.length === 0) {
      return;
    }

    if (navItems[0].classList.contains('first-visible')) {
      prevButton.setAttribute('disabled', 'true');
    } else {
      prevButton.removeAttribute('disabled');
    }
    if (navItems[navItems.length - 1].classList.contains('last-visible')) {
      nextButton.setAttribute('disabled', 'true');
    } else {
      nextButton.removeAttribute('disabled');
    }
  };

  disableButtons(tocListItems);

  innerNav.addEventListener('scroll', () => {
    updateVisibleEls(tocListItems);
    disableButtons(tocListItems);
  });

  const TOCobserver = new IntersectionObserver(
    ([e]) => {
      e.target.classList.toggle('is-pinned', e.intersectionRatio < 1);
    },
    { threshold: [1] }
  );

  TOCobserver.observe(internalNavTOC);
  let selectedElement;

  function activate(clickedElement) {
    if (selectedElement) {
      selectedElement.classList.remove('internal-nav--active');
    }
    selectedElement = clickedElement;
    selectedElement.classList.add('internal-nav--active');
  }

  // Handles onClick Logic for TOC.
  internalNavTOC.addEventListener('click', (e) => {
    if (e.target.tagName === 'A') {
      e.preventDefault();
      scrollToSection(e.target.hash);

      if (e.target.closest('li')) {
        activate(e.target.closest('li'));
      }
    }
  });

  nextButton.addEventListener('click', () => {
    const currentActive = document.querySelector('.last-visible');
    const nextItem = currentActive.nextElementSibling;
    if (!currentActive || !nextItem) return;
    innerNav.scrollLeft += nextItem.offsetWidth + 56;
    updateVisibleEls(tocListItems);
  });

  prevButton.addEventListener('click', () => {
    const currentActive = document.querySelector('.first-visible');
    const prevItem = currentActive.previousElementSibling;
    if (!currentActive || !prevItem) return;
    innerNav.scrollLeft -= prevItem.offsetWidth;
    updateVisibleEls(tocListItems);
  });

  // Handler function that assigns TOC logic once the element is visible on the window.
  let lastShown;
  function handleIntersection(entries) {
    entries.forEach((entry) => {
      const entryEl = document.querySelector(
        `.js-toc-item a[href$="#${entry.target.id}"]`
      );

      if (entryEl && entryEl.closest('li') && entry.isIntersecting) {
        const entryItem = entryEl.closest('li');

        if (lastShown) {
          lastShown.classList.remove('internal-nav--active');
        }
        lastShown = entryItem;
        activate(entryItem);
      }
    });
  }
  // Setting a 0.75 threshold to load the IntersectionObserver once the element
  // 75% visible on the window.
  const options = {
    threshold: 0.75,
  };

  const observer = new IntersectionObserver(handleIntersection, options);
  sections.forEach((section) => {
    observer.observe(document.querySelector(`#${section.id}`));
  });
  observer.takeRecords();
});
